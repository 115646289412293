<template>
  <div class="list">
    <div class="slist">
      <div class="big">
        <div class="div">
          <img class="hecun" v-lazy="hecun" alt="" />
          <div class="kong"></div>
          <h6>你好</h6>
          <h6>欢迎登录</h6>
          <van-form @submit="handleSubmit">
            <van-field
              v-model="account"
              name="account"
              clearable
              :left-icon="zhanghao"
              label=""
              placeholder="请输入账号"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="password"
              type="password"
              clearable
              :left-icon="mima"
              name="password"
              label=""
              placeholder="请输入密码"
              :rules="[{ required: true }]"
            />
           <div class="rember">
             <span>记住密码?</span>
          <van-switch v-model="isrember" />
           </div>
            <div style="margin: 16px">
              <van-button block color="#B3EA81" native-type="submit"
                >登录</van-button
              >
            </div>
          </van-form>
          
        </div>
      </div>
    </div>
    <!--头部-->
  </div>
</template>
<script>
import { Form, Field, Button, Toast,Switch } from "vant";
import { getStorage, removeStorage } from "@/utils/auth";
import BannerImg from "@/assets/images/bgimg.png";
import HeCUnImg from "@/assets/images/hecun.png";
import ZhangHaoImg from "@/assets/images/zhanghao.png";
import MIMaImg from "@/assets/images/mima.png";
import LogBgcImg from "@/assets/images/logbgimg.jpg";
export default {
  name: "Login",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Switch.name]: Switch,
    [Toast.name]: Toast,
  },
  data() {
    return {
      banner: BannerImg,
      hecun: HeCUnImg,
      zhanghao: ZhangHaoImg,
      mima: MIMaImg,
      logimg: LogBgcImg,
      isrember:false,
      // account: getStorage("loginAccount") || "",
      account: "",
      // password: getStorage("loginPassword") || "",
      password: "",
      loading: false,
    };
  },
  created() {
    this.isrember=JSON.parse(getStorage('isrember'))
    if (this.isrember) {
      this.account=getStorage("loginAccount") || ""
      this.password=getStorage("loginPassword") || ""
    }
  },
  methods: {
    //提交登录请求
    handleSubmit() {
      this.loading = true;
      const _param = {
        account: this.account,
        pwd: this.password,
        isrember:this.isrember
      };
      const redirectUrl = getStorage("redirectUrl");
      const redirectUrlQuery = getStorage("redirectUrlQuery");
      this.$store
        .dispatch("login", _param)
        .then((response) => {
          this.loading = false;
          const that = this;
          if (response.code === 201 || response.code === 202) {
            Toast.fail(response.msg);
          } else {
            console.log(redirectUrl);
            Toast.success("登录成功！"),
              setTimeout(function () {
                removeStorage("redirectUrl");
                removeStorage("redirectUrlQuery");
                that.$router.push({
                  path: redirectUrl ? redirectUrl : "/",
                  query: redirectUrlQuery ? JSON.parse(redirectUrlQuery) : {},
                });
              }, 1500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  margin-top: -0.1rem;
}
.slist {
  background-image: url("../../assets/images/logbgimg.jpg");
  background-repeat: no-repeat;
  height: 101vh;
  background-size: 112%;
}
.big {
  // height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .div {
    padding: 0.3rem;
    border-radius: 0.5rem;
    height:11rem;
    width: 85vw;
    position: absolute;
    top: 23%;
    background-color: #ffffff;
    .kong {
      width: 10vw;
      height: 1.2rem;
    }
    .hecun {
      width: 35%;
      position: absolute;
      z-index: 999;
      top: -15%;
      left: 32.5%;
    }
   
    h6 {
      margin-left: 0.5rem;
      margin-bottom: 0.2rem;
      font-size: 0.5rem;
      font-weight: 550 !important;
    }
    .van-form {
      width: 100%;
      .rember{
       display: flex;
       justify-content: start-end;
       align-items: center;
       span{
         margin-left: 0.5rem;
       }
        .van-switch{
          font-size: 0.6rem;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
        span{
       font-size: 0.4rem;
     }
      }
      .van-field {
        margin: 0.5rem 0;
      }
      .van-button {
        color: black !important;
      }
    }
  }
}
// .content{
//   width: 91vw;
//   height: 45vh;
//   background-color: #fff !important;
//   // margin: auto;
//   position: absolute;
//   top: 28%;
//   left: 5%;
//   .form /deep/ {
//   margin-top: 20px;
//   width: 90vw;
//   border: none;
// position: absolute;
// left: -5%;
//   // .btn {
//   //   width: 90%;
//   //   color: black  !important;
//   //   margin: 0.8rem 0.5rem;
//   //   position: absolute;
//   //   left: 5%;
//   // }

// }
//   .kong{
//   width: 10vw;
//   height: 1.2rem;
// }
// }
/deep/ .van-icon {
  font-size: 0.55rem !important;
  margin-right: 0.15rem;
}

body {
  background-color: #fff;
}

// .van-field {
//   margin: 0  0.5rem ;
// }
.img {
  position: relative;
}
// .hecun{
//   width: 35%;
//   position: absolute;
//   top: -15%;
//   left: 32.5%;
// }
</style>
